import IMask from 'imask';
import lightGallery from 'lightgallery';
//import lgFullscreen from 'lightgallery/plugins/fullscreen';
import { Modal } from 'bootstrap/js/index.esm.js';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('input[type=tel]')) {
    telMasksTrigger();
  }

  if (document.querySelector('[data-gallary]')) {
    document.querySelectorAll('[data-gallary]').forEach((el) => {
      lightGallery(el, {
        licenseKey: '0000 0000 0000 0000',
        download: false,
        fullScreen: false,
        //plugins: [lgFullscreen],

        selector: '[data-src]',
        //selector: 'this',
      });
    });
  }

  if (document.querySelector('[data-navclose]')) {
    document.querySelectorAll('[data-navclose]').forEach((el) => {
      el.addEventListener('click', closeNav);
    });
  }

  if (document.querySelector('[data-navtoggler]')) {
    document.querySelectorAll('[data-navtoggler]').forEach((el) => {
      el.addEventListener('click', toggleNav);
    });
  }

  if (document.querySelector('[data-nav]')) {
    document
      .querySelector('[data-nav]')
      .addEventListener('click', navClickHandler);
  }

  if (document.querySelector('#card-form-connect')) {
    document
      .querySelector('#card-form-connect')
      .addEventListener('submit', sendFormData);
  }

  if (document.querySelector('[data-click-info]')) {
    document.querySelectorAll('[data-click-info]').forEach((el) => {
      el.addEventListener('click', addInfoToForm);
    });
  }
});

function sendFormData(e) {
  if (e) {
    e.preventDefault();
  }

  const formData = new FormData(e.target);

  //const formProps = {};
  let formText = '---------------------------\n\nСообщение от бота с сайта\n\n';

  // Заполняем объект formProps значениями формы
  for (let [key, value] of formData.entries()) {
    //formProps[key] = value;
    formText = formText + key + ' - <b>' + value + '</b> \n';
  }

  formText = formText + 'Адрес - ' + window.location.href;
  formText = formText + '\n---------------------------\n';

  console.log(formText);

  fetch(
    'https://api.telegram.org/bot6994731159:AAHs4iQlAE3fzTNOHQYsp0mfA0KR4HiOXsI/sendMessage',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        chat_id: '-1002085152514',
        text: formText,
        parse_mode: 'HTML',
      }),
    }
  )
    .then((response) => response.json())
    .then((data) => {
      console.log('Форма успешно отправлена.');
      console.log(data);

      document.querySelector('.modal.show form').reset();
      document.querySelector('.modal.show [data-bs-dismiss="modal"]').click();

      if (data.ok) {
        showThanks();
      } else {
        showThanksError();
      }
    })
    .catch((error) => {
      console.log('Не удалось отправить форму');
      console.error(error);
    });
}

function showThanks() {
  const modalEl = document.getElementById('thanksModal');
  const thanksModal = new Modal(modalEl);
  thanksModal.show();
}

function showThanksError() {
  const modalEl = document.getElementById('thanksModal');
  modalEl.querySelector('.card__title').textContent =
    'Ошибка. Что-то пошло не так.';
  const thanksModal = new Modal(modalEl);
  thanksModal.show();
}

function telMasksTrigger() {
  document.querySelectorAll('input[type=tel]').forEach((el) => {
    window.telArr = [];

    window.telArr.push(
      IMask(el, {
        mask: '+{7} 000 000 00 00',
      })
    );
  });
}

function closeNav() {
  document.querySelector('[data-nav]').classList.remove('active');
}

function toggleNav() {
  document.querySelector('[data-nav]').classList.toggle('active');
}

function navClickHandler(e) {
  if (e.target.tagName !== 'A') {
    closeNav();
  }
}

function addInfoToForm(e) {
  const thisEl = e.currentTarget;

  if (document.querySelector(thisEl.dataset.bsTarget)) {
    const targetEl = document.querySelector(thisEl.dataset.bsTarget);
    targetEl.querySelector('.dop-info').value = thisEl.dataset.clickInfo;
  }
}
